<template>
  <div class="indexDetails">
    <Head></Head>

    <div class="main">
      <div class="describe">
        <div class="content fcs">
          <div class="left">
            <p>
              {{ detailList.jobName }}<span>{{ detailList.wageMax }}</span>
            </p>
            <p>
              <span>{{ detailList.cityName }}</span
              ><span>{{ detailList.companyExperienceName + "经验" }}</span
              ><span>{{ "招" + detailList.recruitCount + "人" }}</span>
            </p>
            <p>
              <span v-for="item in detailList.welfareName" :key="item">{{
                item
              }}</span>
            </p>
          </div>
          <div class="right">
            <el-button round class="Collection" @click="changeIcon()"
              ><img :src="imgUrl" alt="" /> 收藏</el-button
            >
            <el-button
              round
              @click="deliveryPost()"
              :disabled="detailList.hasDelivery == 1"
              >{{ detailList.hasDelivery == 1 ? "已投递" : "投递" }}</el-button
            >
            <el-button round @click="$router.push('/chart')">联系客服</el-button>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="jobDescription">
          <Title1 :titles="'职位描述'" wh="1024"></Title1>
          <div class="detailed">
            <div class="detailed zwms" v-html="detailList.jobRemark && unEscapeHTML(detailList.jobRemark)"></div>
            <!-- <div>
              <p>岗位职责：</p>
              <ul>
                <li>
                  1、负责公司产品的UI设计，包括不限于移动端App、Web以及微信小程序，把握产品整体视觉风格及效果、提升用户体验；
                </li>
                <li>
                  2、参与制定和维护产品的设计规范，保持产品视觉风格一致性；
                </li>
                <li>3、深入了解用户需求，为业务提供优质的设计方案。</li>
              </ul>
            </div>
            <div>
              <p>任职要求：</p>
              <ul>
                <li>
                  1、负责公司产品的UI设计，包括不限于移动端App、Web以及微信小程序，把握产品整体视觉风格及效果、提升用户体验；
                </li>
                <li>
                  2、参与制定和维护产品的设计规范，保持产品视觉风格一致性；
                </li>
                <li>3、深入了解用户需求，为业务提供优质的设计方案。</li>
              </ul>
            </div>
            <div>
              <p>福利待遇</p>
              <ul>
                <li>1、试用期三个月5000元；</li>
                <li>2、周末双休，国家法定节假日，转正后购买社保；</li>
                <li>3、生日，节日津贴等。</li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="companyProfile">
          <Title1 :titles="'公司介绍'" wh="1024"></Title1>
          <div class="detailed fs">
            <div class="left">
              <div class="top">
                <div>
                  <img :src="detailList.enterpriseLogo" alt="" />
                </div>
                <ul>
                  <li>
                    <span>{{ detailList.enterpriseName }}</span
                    ><span>企业联系人：</span
                    ><span>{{ detailList.legalName }}</span>
                  </li>
                  <li>
                    <span>
                      <div>
                        <img
                          src="@/assets/img/Index_JobSeeker/icon11.png"
                          alt=""
                        />
                      </div>
                      <p class="address" :title="detailList.companyCityName + '·' + detailList.companyAreaName +'·' +detailList.companyAddress">
                      {{
                        detailList.companyCityName +
                        "·" +
                        detailList.companyAreaName +
                        "·" +
                        detailList.companyAddress
                      }}
                      </p>
                    </span>
                    <span>
                      <div>
                        <img
                          src="@/assets/img/Index_JobSeeker/icon12.png"
                          alt=""
                        />
                      </div>
                      {{ detailList.enterpriseSizeName }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="bottom" v-html="detailList.enterpriseRemarke && unEscapeHTML(detailList.enterpriseRemarke)">
              </div>
            </div>
            <div class="right">
              <div>
                <img src="@/assets/img/Index_JobSeeker/icon14.png" alt="" />
              </div>
              <div
                 v-if="detailList.enterpriseVideo"
                class="video fcs"
                @click="
                  (dialogPlay = true), (videoUrl = detailList.enterpriseVideo)
                "
                :style="{
                  background:
                    'url(http://192.168.225.172:8080/img/meeting.b9d27ee4.webp)',
                }"
              >
                <div class="Mantle fcc">
                  <video width="100%" height="100%" :src="detailList.enterpriseVideo"></video>
                  <img src="@/assets/img/Index_JobSeeker/icon13.png" alt="" />
                </div>
              </div>
              <div v-else class="video fcc">
                <img class="imageS" src="@/assets/img/Index_JobSeeker/videoImg.png" alt="" />
              </div>
              <div class="modification"></div>
            </div>
          </div>
        </div>
        <div class="recommendJob">
          <div class="title2 fcc">
            <img src="@/assets/img/Index_JobSeeker/title2.svg" alt="" />
          </div>
          <div class="job">
            <Position
              v-for="(item, index) in recommendPostList.slice(0, num)"
              :key="index"
              :val="item"
              @click.native="goToDetails(item.id)"
            ></Position>
          </div>
        </div>
        <!-- <div class="more" v-show="!more" @click="showMore()">
          <p>查看更多</p>
          <div>
            <img src="@/assets/img/Index_JobSeeker/icon15.png" alt="" />
          </div>
        </div>
        <div class="more" v-show="more">
          <p>已显示全部</p>
        </div> -->
      </div>
    </div>
    <Footer></Footer>
    <el-dialog title="" width="500px" :visible.sync="dialogTableVisible">
      <ul class="xzjl">
        <!-- <li><el-radio v-model="resumeId" :label="detailList.id">在线简历</el-radio></li> -->
        <li v-for="item in fileList" :key="item.id"><el-radio v-model="resumeId" :label="item.id">{{item.resumeName}}</el-radio></li>
      </ul>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="deliveryPost(true)">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="视频预览"
      :visible.sync="dialogPlay"
      width="50%"
      @close="videoUrl = ''"
    >
      <video
        :src="videoUrl"
        controls
        autoplay
        class="video"
        ref="dialogVideo"
        width="100%"
      ></video>
    </el-dialog>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Position from "@/components/Position.vue";
import Title1 from "@/components/Title1.vue";
import {
  indexDetails,
  recommendPost,
  collectionAdd,
  collectionRemove2,
  deliveryAdd,
  viewAdd,
  customer
} from "@/api/index";
import { goToDetail } from "@/utils/public";
import {
  resumeAllList,
} from '@/api/myResume';
export default {
  name: "indexDetails",
  components: {
    Head,
    Footer,
    Position,
    Title1,
  },
  data() {
    return {
      dialogTableVisible: false,
      fileList: [],
      more: false,
      dialogPlay: false,
      videoUrl: "",
      detailList: [],
      resumeId: '',
      recommendPostList: [],
      num: 6,
      userInfo: {},
      imgUrl:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAABHNCSVQICAgIfAhkiAAABL9JREFUaEPtWUt62kgQrhIGnNXgzcyHN3FOMJ4T2D6ByQlCThBzApoThJwgzgnCnCD4BPGcIMzGzMwmZBVbjun5S62GFkigJ3l80QZJqKvrr1fXg+kHuPgHwEA/QXwrWtyuCTU9ajKfk9YdMH3qMq5Jz/A8JuZrX9MbUu1JKmDqY6vOt+cMmkzcAp0Wfo+xVtZPtPwyj3zdvCJ1IHtsvJJByEb0ue8RX2wjYv/H5pc+0SARDATSIOpj025qmkzK1/uvNoGJBbGnpqce6bciJdkMkvpTE4/uaX8UIRYw5UGCc0iUnkXB7Pfcb5uDf15oPVerNEXqTLXZvfr1mkBvj+ioRho0WTR/Eu4/w/69e9W+jAO/BqKupl2P6HX48dWdSC2NmRgpg0kDBsBHvjp8KvcNNX1tpS8C8UW7KWk2oV0LZk56eK8Oe6tAIiBcAJDQwFdtlVbt9ru6+u+Y9cOYmX4R85L3AkBr+kTsdX312yg7zaVgA5NV7ecujQUIMaEa0Tv5c070PEl1aRhwgQRaAQDNtdPAZHJem/gzIODEDfr8wUSKfBpY5S2qEe9pHg2s01xqBGb+xJpkAAI2K7bcx+3VnWqf5hTW+jL4SZ1qrSIaWAdyM0TEfIH3Y/B6ZswVWmjS7Ud5eCA6+6La49JAVEFIrEbfTsTn5lT7QwTEjjOXq4UqAIQ068poA9HqFaLVBTfUzQi+cA73692pw2GFe5dG2nHyCUzqCTfVVMzn5LswJUcM4BsxiAggWECYB9o/SJOnlCbOgoQa/elM/EKi1BIEEBWku9PlrgV9tyDgy+8l8xU3EMe+xsPvNlztVJwFNov4hI1ORVONAvxkXyo1DtEHSWf8QbsFc7pBvcAv4d1vkFh1s1Pc/Qp7tgUZsTpEGWBRoUrDi4Pds5R9x1XrCXMn6xfFstfs7ORY4aRJ9lgIQFiTwu0iqcpBfidLbLJqTUk2XabiYVKlqZy0uRJETsngZhiLA85JBIN8pBImChK1iR/IRJLVyCmN2DvBB4/LKowK8hxZLkWWRw/v5aVbEC3NKfzcLQG/qYTQOLMAOLLpt4twLV9yHGfm0yOUgNubV2VKPI4WQqq0jzpw5r9wDEiTLXLFJn02ucKiawA5+5pAFoexnM5Mx3GtnvjM1ahPOhOPv2bYjbaQkqNmYvq92j9a7fVUbUIugG153cYaYg0IRVuTVQHJAmAtOsUxFQVSvY80BtM+a1LCS1wkiuMxVTUXAKEvl1J3SDtf095Zmb2kgLHgNL59aXu220xoY4hNNBHj7NJHPTFAkrvUmc3MpBPvpFKTGmHO1MnS/0qlCZcpnCPQSNj5TjE72Aaoof5FC/8BXfNgjPA3KsxOVi1nBiFMrbT/Mdnxepl7rUb6wrzMIYIZCM4kjBGyH665QBggSz8JmRhh7oDByvaRV30wfYZR1zBoYBdo+Vst5wZhCciJqjUrM4/ADI95mDSeEuY9E3mOiko/n2NvMm4TWSDZxZQIw0IeyfBwznrGc91B71TMJmBebB8meJHZBBN4KKwJl24467swvd3YC45LqsgAJ45qqSAWG5j5XRfuivMlGNyMwfw4S9jcFtXKN6csO1bwbTWaqIDRTSR/gtixwBO3+x/In3Qsy/jSjgAAAABJRU5ErkJggg=="
    };
  },
  created() {
    if(this.$route.query.kf){
      customer(this.$route.query.id).then((res) => {
        if(res.data.welfareName.indexOf(',')>-1){
          res.data.welfareName = res.data.welfareName
          ? res.data.welfareName.split(",")
          : [];
        } else if(res.data.welfareName.indexOf('，')>-1){
          res.data.welfareName = res.data.welfareName
          ? res.data.welfareName.split("，")
          : [];
        } else {
          res.data.welfareName = [res.data.welfareName]
        }
        this.detailList = res.data;
        this.videoUrl=res.data.enterpriseVideo
        if (res.data.hasCollection == 1) {
          this.imgUrl ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
        }
      })
    } else {
      this.getresumeAllList();
      this.getIndexDetails();
      this.loadviewAdd(this.$route.query.id)
    }
     this.getRecommend();
  },
  mounted() {
    const userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
  },
  methods: {
    // 读取简历信息
    getresumeAllList() {
      resumeAllList({}).then((res) => {
        this.fileList = res.data
        this.resumeId = res.data[0].id
      });
    },
    // 查看详情
    loadviewAdd(id){
      viewAdd({
        addType: 1,
        id
      })
    },
    // 切换收藏图标
    changeIcon() {
      let btn = document.getElementsByClassName("Collection")[0];
      btn.classList.toggle("activity");
      if (document.getElementsByClassName("activity")[0]&&(this.detailList.hasCollection==0||this.detailList.hasCollection==null)) {
        collectionAdd({ content: this.detailList.id, type: 0 }).then((res) => {
          this.imgUrl =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAABGlJREFUaEPtWW2IFVUYft9x7sy6UaCZ7cxqRWVgCGWghlBKZEEIQQRFhGQh9rE/1D4sse65q8sWoQRRP8L60f4LKsGgMtB+FBkUGJhf0GbFzL3uJqSIMXPvzumZ6727e3fvvfO5jpc8sDAz932f93nOx3vec5apwxt3OH+6IiDrEfx/jECuv7SCPe9hIrkaihcQ8UL0fEmSHMbzKWI+6UpliMT1eA/Rdv81O3dOXa+QfAwYBjzmMfEc4FuSGH90SDLvreSNg0FobUcgJ4rPM3mbAL4oCKj2+5cIvsUV5vGm9kJ2aWS/RpJeYOZrQ2AOA+9l4H3Wyra5AHFmgSad/cy0OESQZiYvOsLcPfkHXRQfQg+/j283RsXEKO1zqXsdiTn/TPWdJkDfMbKIxsoHMDUwVRI05lecvPG2j5ArFJ9TZJV8/Cbpd0yrta4wjk4GaRSw01qoV+gQyJvxI014esxPKUQjUsovEAiPyRpwzrisLp+81hoEaML+ER+WJwvT6I05fAGY3WlhYjr94oreO+t44wJyBesZRfKetALNJA5Gtq+cN97zY1wU8ImcpR8t/hZngc0k0dbY0nYM8ybayOWqAF2U1hJ5+7IhEy+qZHrUzZufVgVowvoIuX59PKhsvLC2hrA/rKsLOAwBd2RDJV5UCPgVApbUBNhn8XBNPKhsvPzsBgFXMUnJeqHoZUMjWVTndkOtCtAKxUoaG00yOtG8MQISI6DUspA9Cvd50SAyt/4b9dZ1dQH7QWdN5pQiEMCO/AN25JV1AVvguyuCf+amWLS7ysJ86eJOLErzNfJKeOmYExoELIOAn8YJ68L2Tz+rM+/acASGMf9v8U0nijlhPa0QfxjOP1srlBHbUUYMNAggIVWd7GPQdGu29AKjjzqk3kxi/vlGAXjrhKLOI7mhLHrHy/7pR0phfw0tDwT2QwYGSJ2HkTqXTg49PesMjBpa2T2C4m5uBhxbhqye7GbJu5zXe0+0F4Bf1X77HsWjby+n8gIL9xEs3M+nKmyZ97WCtZUlv3k5jAJy/jvI+ZubcWm7ceGQPwSDJ7MVIQ84ZK4hwU0r5vY7r5AKUus3SK33ZSEC8/6IS7SChHmhVfzg0kHY3RrJ77CoG1b/JRD0p0PKMhI9I+1iBQvwvd8avVr/t+wf+lddAuJ+iFOOirJmu/lHULxwAnwUTCdczO6Z6cM/ps1xV+N7aZvhn1ECW3gBNaicsPpQM70biBzP4Cunq+txenXu2bDukQX4wKqw7ocI/04mtYsA9PwgjojbwhKv28US4DtrO+zFNCb3YkrdFjXoFPvTY6Q8URE9uBGP3mILqIfCXjEAkMg9V/P/wKHZW5vd+4eVkliAHyjXby1VPB7E44NhAmO6/CxJ6SuLHlzlJ2upCKhTUPuLqxTPwzmVcdc6vfnEcYQa9O80k9Ge8E5VwDhs3r5BY3oW4Hfjm/+PjRNjivy48kbv92kRT7yI0yYSF29mRiAumxh+VwTE6LRUXTp+BP4DDzlN7PQGDKIAAAAASUVORK5CYII="
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      } else {
        let form={
          content:this.detailList.id,
          type:0,
          userId:this.userInfo.userId
        }
        collectionRemove2(form).then((res) => {
          this.imgUrl ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAABHNCSVQICAgIfAhkiAAABL9JREFUaEPtWUt62kgQrhIGnNXgzcyHN3FOMJ4T2D6ByQlCThBzApoThJwgzgnCnCD4BPGcIMzGzMwmZBVbjun5S62GFkigJ3l80QZJqKvrr1fXg+kHuPgHwEA/QXwrWtyuCTU9ajKfk9YdMH3qMq5Jz/A8JuZrX9MbUu1JKmDqY6vOt+cMmkzcAp0Wfo+xVtZPtPwyj3zdvCJ1IHtsvJJByEb0ue8RX2wjYv/H5pc+0SARDATSIOpj025qmkzK1/uvNoGJBbGnpqce6bciJdkMkvpTE4/uaX8UIRYw5UGCc0iUnkXB7Pfcb5uDf15oPVerNEXqTLXZvfr1mkBvj+ioRho0WTR/Eu4/w/69e9W+jAO/BqKupl2P6HX48dWdSC2NmRgpg0kDBsBHvjp8KvcNNX1tpS8C8UW7KWk2oV0LZk56eK8Oe6tAIiBcAJDQwFdtlVbt9ru6+u+Y9cOYmX4R85L3AkBr+kTsdX312yg7zaVgA5NV7ecujQUIMaEa0Tv5c070PEl1aRhwgQRaAQDNtdPAZHJem/gzIODEDfr8wUSKfBpY5S2qEe9pHg2s01xqBGb+xJpkAAI2K7bcx+3VnWqf5hTW+jL4SZ1qrSIaWAdyM0TEfIH3Y/B6ZswVWmjS7Ud5eCA6+6La49JAVEFIrEbfTsTn5lT7QwTEjjOXq4UqAIQ068poA9HqFaLVBTfUzQi+cA73692pw2GFe5dG2nHyCUzqCTfVVMzn5LswJUcM4BsxiAggWECYB9o/SJOnlCbOgoQa/elM/EKi1BIEEBWku9PlrgV9tyDgy+8l8xU3EMe+xsPvNlztVJwFNov4hI1ORVONAvxkXyo1DtEHSWf8QbsFc7pBvcAv4d1vkFh1s1Pc/Qp7tgUZsTpEGWBRoUrDi4Pds5R9x1XrCXMn6xfFstfs7ORY4aRJ9lgIQFiTwu0iqcpBfidLbLJqTUk2XabiYVKlqZy0uRJETsngZhiLA85JBIN8pBImChK1iR/IRJLVyCmN2DvBB4/LKowK8hxZLkWWRw/v5aVbEC3NKfzcLQG/qYTQOLMAOLLpt4twLV9yHGfm0yOUgNubV2VKPI4WQqq0jzpw5r9wDEiTLXLFJn02ucKiawA5+5pAFoexnM5Mx3GtnvjM1ahPOhOPv2bYjbaQkqNmYvq92j9a7fVUbUIugG153cYaYg0IRVuTVQHJAmAtOsUxFQVSvY80BtM+a1LCS1wkiuMxVTUXAKEvl1J3SDtf095Zmb2kgLHgNL59aXu220xoY4hNNBHj7NJHPTFAkrvUmc3MpBPvpFKTGmHO1MnS/0qlCZcpnCPQSNj5TjE72Aaoof5FC/8BXfNgjPA3KsxOVi1nBiFMrbT/Mdnxepl7rUb6wrzMIYIZCM4kjBGyH665QBggSz8JmRhh7oDByvaRV30wfYZR1zBoYBdo+Vst5wZhCciJqjUrM4/ADI95mDSeEuY9E3mOiko/n2NvMm4TWSDZxZQIw0IeyfBwznrGc91B71TMJmBebB8meJHZBBN4KKwJl24467swvd3YC45LqsgAJ45qqSAWG5j5XRfuivMlGNyMwfw4S9jcFtXKN6csO1bwbTWaqIDRTSR/gtixwBO3+x/In3Qsy/jSjgAAAABJRU5ErkJggg=="
          this.$message({
            message: res.msg,
            type: "success",
          });
        });
      }
    },

    // 获取招聘详情
    getIndexDetails() {
      indexDetails(this.$route.query.id).then((res) => {
        if(res.data.welfareName.indexOf(',')>-1){
          res.data.welfareName = res.data.welfareName
          ? res.data.welfareName.split(",")
          : [];
        } else if(res.data.welfareName.indexOf('，')>-1){
          res.data.welfareName = res.data.welfareName
          ? res.data.welfareName.split("，")
          : [];
        } else {
          res.data.welfareName = [res.data.welfareName]
        }
        this.detailList = res.data;
        if (res.data.hasCollection == 1) {
          this.imgUrl =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAjVJREFUSEu1lU1rE1EYhc+509zRVhd1FrYpqF1EhP4JBRHRLrqJCxVXUhSJRbGLVkluUaN2oVnUjSC4sFsXtgsRxL8hBrqKSVUE6YdJbjrzSlpjU9tkMpbO8r7POed+zH0vsccf99gf2wOefD+oVytnQCchIssB1IKvKh9hBitbJmNEOVg8S/gnlCgNcsGi8h5m8GcztxmQyrsxr+cxgVEC3c2QCH4IMVkz8Rf1cdcsDguCpwQSWzigDHDa4tMDmFNr9dpGQCrvul73PMDT7beM4wFRViIzIdyHKirD9VWvB2hTmiZkvJPzEMAn4ISxAfCsZuK3ieySp+1KgcC+MFGUugBVC3WEOl28RIXXUcSdskGAK9SmeI/A/U5FUTgh09SZ4iSJh1GEnbIiuEs3XTgPpeY7FUXhBEwSoxLT/aWvJHqjiMNYAcr219Lh9d80Zgo5BTUWJopWl5dVM3B146JNFDztqs8EDkUz2ZkWYMVaexzZY6W/rcIxxREHeMPG7d5FkhCXbSY+u9kq/phpU8wSmNiFNwLITM0MpBoe27qpznyZJXnxf0JEZM4yPgLDoGUAkuK4Q6V6M7sWJUSEc9brS+Imq826lg9ObKp0gyI5Al1hQYEgVxvqv4ML9P9l275oXaZ00oG8AnB05xAW18Drvul722oS4U+m+XZA07+FIBgj6dWNBFgG8Nxi9RFMYqndCsMDGupU3tW9PeeggpjF/ncwXlvj1occtuER652vIKJxA/8No7i0Sa/cNdoAAAAASUVORK5CYII=";
        }

        const m7CardInfo = {
        "right1": {
              "text": this.detailList.jobName + '  ' + this.detailList.wageMax,  // 首行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#333",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
              "fontSize": 16                      // 字体大小， 默认12 ， 请传入number类型的数字
          },
          "right2": {
              "text": this.detailList.cityName + '   ' + this.detailList.companyExperienceName + "经验",        // 第二行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#666666",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#595959
              "fontSize": 14                      // 字体大小， 默认12 ， 请传入number类型的数字
          },
          "right3": {
              "text":  "招" + this.detailList.recruitCount + "人" ,           // 第三行文字内容，展示时超出两行隐藏，卡片上单行隐藏
              "color": "#999999",                 // 字体颜色，支持十六位 #ffffff 格式的颜色，不填或错误格式默认#ff6b6b
              "fontSize": 12                      // 字体大小， 默认14 ， 请传入number类型的数字
          },
          "extraInfos": ["随时随地－连接企业与用户"],             // 额外信息，访客不可见，座席点击卡片上的更多可见，可不填，字符串形式的文本数组
          "url": "http://danlingzp.com/indexDetails?kf=true&id=" + this.detailList.id                    // 点击可跳转的链接
      }
      sessionStorage.setItem('m7CardInfo', JSON.stringify(m7CardInfo))
      });
    },
    // 获取推荐职位
    getRecommend() {
      recommendPost({pageNum: 1, pageSize: 6}).then((res) => {
        this.recommendPostList = res.rows;
      });
    },
    //  跳转详情页
    goToDetails(id) {
      if (goToDetail()) {
        this.$router.push({ path: "/indexDetails", query: { id: id } });
        location.reload();
      }
    },

    // 投递
    deliveryPost(isend) {
      if(this.fileList.length>1 && !isend){
        this.dialogTableVisible = true
        return
      }
      let arr = {
        hasPush: 1,
        enterpriseId: this.detailList.enterpriseId,
        recruitId: this.detailList.id,
        resumeId: this.resumeId || ''
      };
      deliveryAdd(arr).then((res) => {
        this.getIndexDetails();
        this.$message.success(res.msg);
        this.dialogTableVisible = false
      });
    },

    // 查看更多
    showMore() {
      this.more = true
      this.num += this.recommendPostList.length;
    },
    unEscapeHTML(html) {
      html= "" +html;
      return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    },
  },
};
</script>
<style scoped>
.indexDetails {
  width: 100%;
  height: 100%;
}
.indexDetails .main {
  min-height: calc(100% - 278px);
}
.indexDetails .describe {
  height: 182px;
  background: #edf5ff;
  padding: 24px 0;
  margin-bottom: 20px;
}
.indexDetails .describe .left p:nth-child(1) {
  height: 40px;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
  margin-bottom: 24px;
}
.indexDetails .describe .left p:nth-child(1) span {
  margin-left: 48px;
  color: #ff2647;
}
.indexDetails .describe .left p:nth-child(2) {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 24px;
}
.indexDetails .describe .left p:nth-child(2) span {
  padding: 0 16px;
  border-right: 1px solid #666;
}
.indexDetails .describe .left p:nth-child(2) span:nth-child(1) {
  padding-left: 0;
}
.indexDetails .describe .left p:nth-child(2) span:last-child {
  padding-right: 0;
  border-right: none;
}
.indexDetails .describe .left p:nth-child(3) span {
  height: 26px;
  padding: 0 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #bedaff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #6aaaff;
  line-height: 26px;
  text-align: center;
  margin-right: 16px;
}
.indexDetails .describe .left p:nth-child(3) span:last-child {
  margin-right: 0;
}
.indexDetails .describe .right {
  height: 134px;
  display: flex;
  align-items: center;
}
.indexDetails .describe .right /deep/ .el-button {
  width: 160px;
  height: 44px;
  line-height: 44px;
  padding: 0;
  border-radius: 24px;
  border: 1px solid #0780e6;
  font-weight: 400;
  color: #0780e6;
  margin-right: 24px;
  background-color: transparent;
}
.indexDetails .describe .right /deep/ .el-button:nth-child(2) {
  color: #fff;
  background-color: #0780e6;
}
.indexDetails .describe .right /deep/ .el-button img {
  vertical-align: middle;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.indexDetails .jobDescription {
  background: #ffffff;
  padding: 24px 16px;
  margin-bottom: 17px;
}
.indexDetails .jobDescription .detailed {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
.indexDetails .jobDescription .detailed p {
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #333333;
  margin: 24px 0 16px 0;
}
.indexDetails .jobDescription .detailed ul li {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  height: 28px;
}

.indexDetails .companyProfile {
  max-height: 445px;
  background: #ffffff;
  padding: 24px 16px;
  margin-bottom: 50px;
}
.indexDetails .companyProfile .left .top {
  margin: 24px 0 32px 0;
  display: flex;
}
.indexDetails .companyProfile .left .top div {
  width: 68px;
  height: 68px;
  margin-right: 16px;
}
.indexDetails .companyProfile .left .top img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.indexDetails .companyProfile .left .top li:nth-child(1) span:nth-child(1) {
  height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  line-height: 33px;
  margin-right: 24px;
}
.indexDetails .companyProfile .left .top li:nth-child(1) span:nth-child(2) {
  font-size: 14px;
  color: #999;
}
.indexDetails .companyProfile .left .top li:nth-child(1) span:nth-child(3) {
  font-size: 14px;
  color: #333;
}
.indexDetails .companyProfile .left .top li:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  /*margin-top: 15px;*/
}
.indexDetails .companyProfile .left .top li:nth-child(2) span {
  margin-right: 32px;
  display: flex;
  width: 273px;
  white-space: normal;
  margin-top: 15px;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}
.address{
  width: auto !important;
  /*flex: 1;*/
  white-space: pre-line;
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}

.indexDetails .companyProfile .left .top li:nth-child(2) span:nth-child(2) {
  margin-right: 0;
}
.indexDetails .companyProfile .left .top li:nth-child(2) div {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.indexDetails .companyProfile .left .top li:nth-child(2) img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.indexDetails .companyProfile .left .bottom {
  width: 522px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
.indexDetails .companyProfile .right {
  width: 506px;
  margin-top: 24px;
  position: relative;
}
.indexDetails .companyProfile .right img{
  width: 58px;
}

.indexDetails .companyProfile .right .video {
  width: 397px;
  height: 298px;
  margin-top: 12px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}
.indexDetails .companyProfile .right .video .imageS{
  width: 112px;
}
.indexDetails .companyProfile .right .video .Mantle {
  position: absolute;
  width: 397px;
  height: 298px;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
}
.indexDetails .companyProfile .right .video .Mantle img {
  width: 58px;
  height: 58px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -29px;
  margin-left: -29px;
  cursor: pointer;
  z-index: 9;
}
.indexDetails .companyProfile .right .modification {
  position: absolute;
  width: 146px;
  height: 198px;
  background: linear-gradient(109deg, rgba(190, 218, 255, 0) 0%, #c3dafc 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  z-index: 1;
  right: 0px;
  bottom: -10px;
}
.indexDetails .recommendJob {
  margin-bottom: 40px;
}
.indexDetails .recommendJob .title2 {
  height: 98px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 45%,
    #ffffff 100%
  );
  margin-bottom: 20px;
}
.indexDetails .recommendJob .job {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.indexDetails .recommendJob .job::after {
  display: block;
  content: "";
  width: 386px;
}
.indexDetails .more {
  height: 98px;
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 45%,
    #ffffff 100%
  );
  margin-bottom: 40px;
  text-align: center;
  padding-top: 28px;
}
.indexDetails .more p {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #0780e6;
  line-height: 22px;
  margin-bottom: 8px;
}
.xzjl{
  overflow: hidden;
}
.xzjl li{
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}
.zwms{
  white-space: pre-wrap;
}
</style>
