// import { confirm } from 'element-ui';
import Vue from 'vue'
// import router from './router'
// const that=this
export function goToDetail() {
    if (!sessionStorage.getItem('userInfo')) {
        Vue.prototype.$confirm('请先登录才能查看哦！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error'
        }).then(() => {
            location.href = '/login';
        })
        return false
    }else{
        return true
    }

}