<template>
  <div class="title1 fcs">
    <p class="icon1">
      <img src="@/assets/img/Index_JobSeeker/icon10.png" alt="" />
    </p>
    {{ titles }}
    <p class="line" :style="{width:wh+'px'}"></p>
    <p class="icon2">
      <img src="@/assets/img/Index_JobSeeker/icon9.png" alt="" />
    </p>
  </div>
</template>
<script>
export default {
  name: "titles",
  props: ["titles",'wh'],
};
</script>
<style scoped>
.title1 {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  align-items: center;
}
.title1 .icon1 {
  margin-right: 8px;
}
.title1 .line {
  height: 1px;
  border-bottom: 1px dotted #ccc;
  margin-left: 10px;
}
.title1 .icon2 img {
  vertical-align: middle;
}
</style>
